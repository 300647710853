import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { AppTokenSchema } from '../../shared/schemas';
import { reformatListResponse, reformatEntityResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class AppTokenApi {
  private endpoint = 'app_tokens/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    const params = { allow_deleted: '1' };

    return this.gateway.get(this.endpoint, { params: params }).pipe(
      map((res) => reformatListResponse('AppToken', res)),
      map((data) => normalize(data, arrayOf(AppTokenSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(appTokenData): Observable<any> {
    const params = { allow_deleted: '1' };

    return this.gateway.post(this.endpoint, appTokenData, { params: params }).pipe(
      map((res) => reformatEntityResponse('AppToken', res)),
      map((data) => normalize(data, AppTokenSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(id: string, appTokenData): Observable<any> {
    const params = { allow_deleted: '1' };

    return this.gateway.put(this.endpoint + id, appTokenData, { params: params }).pipe(
      map((res) => reformatEntityResponse('AppToken', res)),
      map((data) => normalize(data, AppTokenSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  deactivate(id: string): Observable<any> {
    return this.gateway.get(this.endpoint + id + '/deactivate');
  }

  activate(id: string): Observable<any> {
    return this.gateway.get(this.endpoint + id + '/activate');
  }
}
