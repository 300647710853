import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[AppToken]';

export const AppTokenActionType = {
  LOAD: `${ACTION_PREFIX} Load Apps`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Apps Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Apps Failed`,

  DEACTIVATE: `${ACTION_PREFIX} Deactivate`,
  DEACTIVATE_SUCCESS: `${ACTION_PREFIX} Deactivate Success`,
  DEACTIVATE_FAILED: `${ACTION_PREFIX} Deactivate Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,
};

export class AppTokenAction {
  static load(): Action {
    return {
      type: AppTokenActionType.LOAD,
    };
  }

  static loadSuccess(apps): Action {
    return {
      type: AppTokenActionType.LOAD_SUCCESS,
      payload: apps,
    };
  }

  static loadFailed(err): Action {
    return {
      type: AppTokenActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(appTokenData): Action {
    return {
      type: AppTokenActionType.ADD,
      payload: appTokenData,
    };
  }

  static addSuccess(appTokenResponse): Action {
    return {
      type: AppTokenActionType.ADD_SUCCESS,
      payload: appTokenResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: AppTokenActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(id, appTokenData): Action {
    return {
      type: AppTokenActionType.UPDATE,
      payload: appTokenData,
    };
  }

  static updateSuccess(appTokenResponse): Action {
    return {
      type: AppTokenActionType.UPDATE_SUCCESS,
      payload: appTokenResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: AppTokenActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static deactivate(id): Action {
    return {
      type: AppTokenActionType.DEACTIVATE,
      payload: id,
    };
  }

  static deactivateSuccess(id): Action {
    return {
      type: AppTokenActionType.DEACTIVATE_SUCCESS,
      payload: id,
    };
  }

  static deactivateFailed(id): Action {
    return {
      type: AppTokenActionType.DEACTIVATE_FAILED,
      payload: id,
    };
  }

  static activate(id): Action {
    return {
      type: AppTokenActionType.ACTIVATE,
      payload: id,
    };
  }

  static activateSuccess(id): Action {
    return {
      type: AppTokenActionType.ACTIVATE_SUCCESS,
      payload: id,
    };
  }

  static activateFailed(id): Action {
    return {
      type: AppTokenActionType.ACTIVATE_FAILED,
      payload: id,
    };
  }
}
