import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { mapAndSortEntities } from '../../shared/entity.helper';
import { AppTokenAction } from './app-token.action';
import { AppTokenApi } from './app-token.api';
import { AppTokenModel } from './app-token.model';

@Injectable()
export class AppTokenService {
  constructor(
    private store: Store<AppState>,
    private api: AppTokenApi,
  ) {}

  load() {
    this.store.dispatch(AppTokenAction.load());

    return this.api.load().pipe(
      tap((response) => this.store.dispatch(AppTokenAction.loadSuccess(response))),
      catchError((response) => {
        this.store.dispatch(AppTokenAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  activate(id: string) {
    this.store.dispatch(AppTokenAction.activate(id));

    return this.api.activate(id).pipe(
      tap((response) => this.store.dispatch(AppTokenAction.activateSuccess(id))),
      catchError((response) => {
        this.store.dispatch(AppTokenAction.activateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  deactivate(id: string) {
    this.store.dispatch(AppTokenAction.deactivate(id));

    return this.api.deactivate(id).pipe(
      tap((response) => this.store.dispatch(AppTokenAction.deactivateSuccess(id))),
      catchError((response) => {
        this.store.dispatch(AppTokenAction.deactivateFailed(id));
        return observableThrowError(response);
      }),
    );
  }

  save(appTokenData) {
    if (appTokenData.id) {
      return this.update(appTokenData.id, appTokenData);
    }

    const { id, ...payload } = appTokenData;

    return this.add(payload);
  }

  add(appTokenData): Observable<any> {
    this.store.dispatch(AppTokenAction.add(appTokenData));

    return this.api.add(appTokenData).pipe(
      tap((response) => this.store.dispatch(AppTokenAction.addSuccess(response))),
      catchError((response) => {
        this.store.dispatch(AppTokenAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id: string, appTokenData) {
    this.store.dispatch(AppTokenAction.update(id, appTokenData));

    return this.api.update(id, appTokenData).pipe(
      tap((response) => this.store.dispatch(AppTokenAction.updateSuccess(response))),
      catchError((response) => {
        this.store.dispatch(AppTokenAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }
}

export const getAppTokenState = (state: AppState) => state.orm.appToken;
export const getAppTokensIds = compose((state) => state.items, getAppTokenState);
export const getAppTokensEntities = createSelector(getAppTokenState, (state) => state.itemsById);

export const sortAppTokens = (appTokens: AppTokenModel[]) =>
  appTokens.sort((a, b) => {
    if (a.deleted && !b.deleted) return 1;
    if (!a.deleted && b.deleted) return -1;
    return a.name.localeCompare(b.name);
  });

export const getAppTokens = createSelector(getAppTokensIds, getAppTokensEntities, mapAndSortEntities(sortAppTokens));
export const getActiveAppTokens = createSelector(getAppTokens, (appTokens) =>
  appTokens.filter((appToken) => !appToken.deleted),
);
export const getAppToken = (appTokenId) => compose((appTokenById) => appTokenById[appTokenId], getAppTokensEntities);
